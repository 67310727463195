.Modal {
    max-width: 70rem !important;
    width: 100% !important;
    position: relative;
        .ant-upload-list-item:hover .ant-upload-list-item-info {
            background-color: unset !important;
        
            .anticon-delete svg {
                color: $primary;
            }
        }
        
        .ant-upload-list-item {
            * {
                color: $white40;
            }
        }
    &-back-button {
        position: absolute;
        top: 1.8rem;
        left: 1.8rem;
        color: $primary;
        cursor: pointer;
        font-family: $Jetbrains;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.6rem;
        text-align: center;
        display:flex;
        align-items:center;
        padding: 0.5rem;
    }
    &-description {
        text-align: center;
        padding-bottom: 2rem;
    }
    &-chevron {
        margin-right: 1rem;
    }
    .ant-modal {
        &-header {
            display: flex;
            justify-content: center;
        }
        &-title {
            color: $white;
        }
        &-close {
            color: $white40;
            &:hover {
                color: $primary;
            }
        }
        &-content {
            padding: 3.2rem;
            padding-bottom: 8.6rem;
            background-color: $grey;
            border-radius: 0.5rem;
        }
        &-body {
            background-color: $grey;
            color: $white;
            padding: 0;
            padding-top: 5.2rem;
        }
        &-footer {
            background-color: $grey;
            border-top: none;
            padding: 0;
            padding-top: 1.5rem;
            display: flex;
            justify-content: center;
            & .modal-cancel-btn {
                background-color: $white;
                color: $primary;
                outline: none;
                border: none;
            }
        }
    }
}

