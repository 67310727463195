.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
    cursor: default;
}

.no-drag {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.flex {
    display: flex;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.truncate {
    white-space: nowrap;
    /* Prevents the text from wrapping */
    overflow: hidden;
    /* Hides the text that overflows the container */
    text-overflow: ellipsis;
    /* Adds the ellipsis (...) to the end of the text */
}

// Overwrite -webkit-line-clamp as needed
.multi-line-truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-space {
    margin: 0;
    /* Removes default margin */
    padding: 0;
    /* Removes default padding */
    line-height: 1;
    /* Ensures the line height matches the text size */
}

.turn-svg-white {
    filter: brightness(0) saturate(100%) invert(1); // Turns red to white
}

.absolute-center {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}