.Admin {
    @extend .group-subpage;
    width: 100%;
    height: fit-content;
    position: relative;
    max-width: 105.5rem;
    .Select {
        width: 30rem;
    }

    .Setting-switch-wrapper {
        padding-right: 5rem;
    }
    &-left {
        width: 33.8rem;
        margin-right: 2.5rem;
        display: flex;
        flex-direction: column;
        height: 25rem;
    }
    &-top {
        display: flex;
    }
    &-right {
        width: 100%;
    }
    &-delete {
        cursor: pointer;
        padding: 1rem;
        display: flex;
        position: absolute;
        right: 1.5rem;
        margin-top: -1rem;
        &-icon {
            cursor: pointer
        }
        &-text {
            margin-left: 1.2rem;
            color: $primary;
            font-family: $Jetbrains;
            font-size: 1.2rem;
            font-weight: 300;
            line-height: 2.2rem;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }

    }
    &-bottom {
        display: flex;
        justify-content: end;
        gap: 1rem;
    }
    &-image {
        width: 33.8rem;
        height: 100%;
        position: relative;
    }
    &-image-upload-icon {
        @extend .absolute-center;
        z-index: 5;
        pointer-events: none;
    }
&-image-inner {
    width: 100%;
    height: 100%;
    width: 33.8rem;
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
    position: relative;

    &::after {
        z-index: 4;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4); // 50% black tint
        pointer-events: none; // Allow clicks to pass through
        transition: background-color 0.15s ease-in-out;
    }

    // Move hover to parent
    &:hover {
        &::after {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
}
    &-group-title {
        font-family: $Bebas;
        font-size: 2.6rem;
        line-height: 2.4rem;
    }

    &-icon {
        display: inline-block;
        align-self: flex-start;
        margin-right: 4.5rem;
        width: 2rem;
        height: 2rem;
    }
}

.ant-upload.ant-upload-btn {
    padding: 0 !important;
    outline: 0 !important;
    border: 0 !important;
            border-radius: 0.5rem !important;
}

.ant-upload.ant-upload-drag {
        padding: 0 !important;
            outline: 0 !important;
            border: 0 !important;
            border-radius: 0.5rem !important;
}