.roadbook-toast {
    transform: translateY(-10rem);
}

.edit-mode {
    width: 100%;
}
.Roadbook {
    @extend .group-subpage;
    width: 100%;

    &-main-content {
        display: flex;
    }

    &-waypoint-map-address {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-waypoint-icon {
        position: relative;
        left: -50%;
        top: -50%;
        transform: translate(-50%, -50%);
    }

    &-no-days {
        color: #fff;
        border-radius: 0.3rem;
        background: #2b2b2b;
        padding: 3rem;
    }

    &-waypoint-map-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        top: -5rem;
        width: 20rem;
        text-align: center;
    }

    &-top {
        @extend .group-subpage-top;
    }

    &-add-day-btn {
        margin-right: 0.8rem;
    }

    &-edit-btn {}

    &-cancel-btn {
        margin-right: 0.8rem;
    }

    &-add-waypoint-btn {
        // margin-right: 4.4rem;
    }

    &-left {
        padding-right: 5rem;
    }

    &-right {
        position: relative;
        width: 100%;
        margin-top: 3.9rem;
        height: 70rem;
        overflow: scroll;
    }

    .map-dimensions {
        height: 70rem;
        width: 75rem;
    }

    &-map-loading {
        @extend .map-dimensions;
    }

    &-mapcontainer {
        @extend .map-dimensions;
    }

    &-map {
        @extend .map-dimensions;
        z-index: 1;
        position: relative;

        &-title {
            margin-bottom: 1.2rem;
        }
    }

    &-map-fader {
        height: 100%;
        width: 100%;

        .edit-mode & {
            z-index: 2;
            background-color: black;
            opacity: 0.5;
        }
    }


    &-editing-mode-text {
        color: $white;
        position: absolute;
        background: none;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3rem;
        width: 100%;
        text-align: center;

        .edit-mode & {
            display: block;
        }
    }

    &-header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2rem;
    }

    &-spinner {
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
    }
}

.react-datepicker {
    font-size: 1em;

    &__header {
        padding-top: 0.8em;
        position: absolute;
        width: 21em;
        z-index: 2;
        outline: none;
        font-family: $Jetbrains;
        font-variation-settings: 'wght' 600;

        &--time {
            width: 5em !important;
            overflow: visible !important;
            border-bottom: none;
        }
    }

    &__time-container {
        padding-top: 1.6em;
    }

    &-time__header {
        margin-top: 0 !important;
        padding: 0 !important;
    }

    &__month {
        margin: 0.4em 0.4em;
        margin-top: 4.8em;
    }

    &__current-month {
        font-size: 1em;
    }

    &__navigation {
        top: .8em;
        z-index: 3;

        &--previous {
            left: 4em !important;
        }

        &--next {
            right: 4em !important;
        }
    }

    &__navigation--previous {
        border-right-color: #ccc;
        left: 1em;
    }

    &__navigation--next {
        border-left-color: #ccc;
        right: 1em;
    }

    &__header--time {
        // display: none;
    }

    &__time-list {
        height: 13.15em !important;
        padding-top: 2.8em;

        &-item {
            font-size: .95em;
            font-weight: 400 !important;
        }
    }

    &__day-names {
        margin-left: -5.5em;
    }

    &__day-name,
    &__day {
        width: 1.9em;
        line-height: 1.9em;
        margin: 0.166em;
        font-size: .95em;
    }
}



.react-datepicker-time {
    &__header {
        font-size: 1em;
        padding: .86em;
    }
}