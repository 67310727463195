.GroupDetailsNavbar {
    @extend .group-subpage;
    padding: 0;
    margin-top: 9.5rem;
    background-color: $grey;
    width: 100%;
    background: $pure_black;
    display: flex;
    align-items: center;
    
    &-selected {
        background: linear-gradient(to right,
                    #861A28 0%,
                    #453234 70%, // Darker red for middle transition
                    transparent 100%);
    }

    &-edit-ellipses {
        margin-left: auto;
        cursor: pointer;
    }
    &-option {
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $white;
        height: 16rem;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        padding-left: 3.8rem;
        padding-right: 3.8rem;
        height: 4rem;

        font-family: $Bebas;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.7rem;
        letter-spacing: .15rem;;
        text-align: left;

    }
    &-edit-icon {
        display: flex;
        margin-left:auto;
        align-items: center;
    }
}
