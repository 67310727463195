.Select {
    width: 100%;
    &-title {
        @extend .joinr-form-subtitles;
    }
}

.ant-select {
    &-selector {
        * {
            padding: 0 !important;
        }

        padding: 1.3rem 2rem !important;
        height: $input_height !important;
        font-size: 1.6rem;
        background: $input-grey !important;
        border: none !important;
        border-radius: 0.5rem !important;
        color: $white;
    }

    &-selection-item,
    &-selection-search-input {
        height: $input_height !important;
    }

    &-selection-search-input {
        padding: 1.3rem !important;
    }

    &-arrow {
        color: #999999;
    }

    &-item {
        &.ant-select-item-option {

            &-active,
            &-selected {
                background-color: $black !important;
            }
        }
    }
}