$select_bar_height: 3.5rem;
$option_icon_bar_height: 4.5rem;

.Sidebar {
    position: relative;
    padding-left: 0;
    background-color: $grey;
    z-index: $z_index_sidebar;
    min-width: $sidebar_width;
    height: 100%;
        &-text-generic {
            font-family: $Bebas;
            letter-spacing: .15rem;
            color: $white;
            text-transform: uppercase;
        }
        &-logo-container {
            position: relative;
            display: flex;
            margin-right: 9.5rem;
            justify-content: flex-start;
            width: 100%;
            border-bottom: 1px solid $primary;
            padding: 1.6rem 2rem;
        }

        &-access-text {
            flex-direction: column;
            align-items: flex-end;
            position: absolute;
            right: 0.5rem;
            bottom: -0.7rem;
            font-weight: 200;
            font-style: italic;
            font-size: 2.0rem;
            @extend .no-select;
        }
    
        &-logo {
            width: 9.4rem;
            height: auto;
            cursor: pointer;
            margin-left: 0.1rem;
        }
    &-highlighted {
        position: relative;
        &::after {
            content: '';
            height: 100%;
            width: 0.6rem;
            border-top-left-radius: 0.7rem;
            border-bottom-left-radius: 0.7rem;
            background: $primary;
            left: 1.6rem;
            position: absolute;
            z-index: 1;
        }
        &::before {
            content: '';
                position: absolute;
                top: 0;
                left: 1.6rem;
                border-radius: 0.7rem;
                width: 260px; //TODO: change
                /* Adjust width of the highlight */
                height: 100%;
                background: linear-gradient(to right, $primary, transparent);
                z-index: -1;
                opacity: 0.6;
        }
    }
    &-selected {
        .Sidebar-option-icon-wrapper {
            @extend .Sidebar-highlighted;
        }
    }
    &-options-container {
        display: flex;
        flex-direction: column;
    }
    &-option {
        margin-top: .3rem;
        margin-bottom: .3rem;
        &:first-child {
            margin-top: 9rem;
        }

        // LOGOUT is last-child
        &:last-child {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: $primary;
        }
    }
    &-option-content {
        display: flex;
        align-items: center;
        cursor: pointer;

    }
    &-option-icon-wrapper {
        display: flex;
        justify-content: center;
        // width: 8rem;
        height: $option_icon_bar_height;
    }
    &-option-icon {
        width: 25px;
        height: auto;
        margin-left: 3rem;
        margin-right: 1.5rem;
    }
    &-option-text {
        @extend .Sidebar-text-generic;
        margin-top: 0.1rem;
    }

    &-option-dropdown-chevron {
        margin-left: auto;
        margin-right: 4rem;
        & > img {
            width: 0.8rem;
            @extend .turn-svg-white;
        }
        transition: transform .2s;
        &.active {
            transform: rotate(-90deg);
        }
    }

    // When navitems have subitems
    &-dropdown-container {
        margin-left: 5rem; // TODO: arbitrary value atm
        display: flex;
        flex-direction: row;
        position: relative;
    }

    &-start-end-icon {
        width: 2rem;
        height: 2.5rem;
        margin-top: 3.2rem;
        margin-left: 1rem;
        margin-right: 1rem;

         // subitem dots
        &-container {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                width: 1.4rem;
                left: -1rem;
                top: 1.4rem;
                // TODO: static value atm
                height: calc(100% - 1.6rem);
                background-image: url('../../../src/assets/img/sidebar/white-dot.svg');
                background-size: 100% .7rem;
            }
            &::after{
                content: "";
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    width: 1.4rem;
                    left: -1.1rem;
                    // height: calc(calc(100% - 8rem) + 3.7rem);
                
                    // TODO: static value atm
                    height: calc(100% - 1.6rem);
                    background-image: url('../../../src/assets/img/sidebar/red_dot.svg');
                    background-size: 100% 1rem;
                    background-repeat: no-repeat;
                    bottom: calc(-100% + 3.1rem);
            }
        }
    }

    &-dropdown-items {
        margin-left: 2rem;
    }
    &-dropdown-item {
        cursor: pointer;
        transition: color .2s;
        &:hover {
            color: $primary;

        }
    }
    &-item-text {
        @extend .Sidebar-text-generic;
    }
}
