.GroupCardHorizontal {
    $imageHeight: 25rem;
    $textContentHeight: 8.8rem;
    $textContentWidth: 55.7rem;

    &-group-cards {
        width: 100%;
    }
    &-group-card {
        display: flex;
        max-width: 105rem;
        height: 21rem;
        background-color: $grey;
        margin-bottom: 2rem;
        position: relative;

        // Tint effect only on image
        &-image {
            position: relative; // Position for the tint effect
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5); // Initial tint
                pointer-events: none; // Allow clicks to pass through
                transition: background-color 0.2s ease-in-out; // Smooth transition
                opacity: 1; // Start with full opacity
            }
        }

        // Tint effect removes on hover of the card
        &:hover &-image::after {
            background-color: rgba(0, 0, 0, 0.2); // Change tint on hover
        }
    
        // Handle red tint gradient on card text
        &:hover {
            background: linear-gradient(90deg, rgba(221, 23, 51, 1) 25%, rgba(38, 38, 38, 1) 85%);
            .GroupCardHorizontal-secret-value {
                font-weight: 500;
                color: $white;
                text-shadow:
                    -1px -1px 0 black,
                    1px -1px 0 black,
                    -1px 1px 0 black,
                    1px 1px 0 black;
                /* Black outline */
                
            }
    
            .GroupCardHorizontal-copy-svg {
                @extend .turn-svg-white;
            }
        }
    }
    &-text-content {
        z-index: 5;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-group-card-image {
        flex-direction: column;
        display: flex;
        cursor: pointer;
        // height: $imageHeight;
        height: 100%;
        width: 100%;
        padding-left: 1.9rem;
        padding-bottom: 1.2rem;
        background-size: cover;
        background-position: center;
    }
        
    &-participants {
        color: $white;
    }

    &-title-value {
        cursor: pointer;
        font-family: $Bebas;
        font-size: 2.6rem;
        line-height: 2.2rem;
        @extend .truncate;
    }

    &-card-description {
        margin-top: auto;
        left: 1.9rem;
        font-weight: 200;
        line-height: 18px;
        z-index: 1;
        font-size: 1.4rem;
        font-weight: 200;
        line-height: 2.2rem;
        text-align: left;
        @extend .multi-line-truncate;
        -webkit-line-clamp: 5;
    }
    &-divider {
        height: 1px;
        width: 100%;
        background-color: $white;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    &-left {
        flex: 1;
        position:relative;
    }
    &-right {
        flex: 1;
        padding: 2.5rem 3.2rem;
    }
        &-card-wrapper {
        z-index: 5;
            display: flex;
            position: relative;
                    cursor: pointer;
                        width: fit-content;
    
            &.key {}
    
            &.count {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 1.5rem 2.0rem 0 0;
                cursor: pointer;
                font-family: $Jetbrains;
                font-weight: 200;
                margin-left: auto;
            }
        }
    
        &-card-icon {
            color: #999999;
            width: 3rem;
            margin-right: 1rem;
    
            &.count {
                margin-right: 0;
            }
    
            &.copy {
                margin-left: 1rem;
                @extend .turn-svg-white;
            }
        }
    
        &-copy-svg {
            height: 2rem;
            max-width: 100%;
            max-height: auto;
            margin-bottom: 0.3rem;
        }
    
        &-count-svg {
            // height: 2rem;
            max-width: 2rem;
            max-height: auto;
        }
    
        &-secret-value {
            font-weight: 500;
            color: $white;
        }
    
        &-member-count-value {
            // color: #999999;
            @extend .no-space;
        }
}