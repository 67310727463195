.Gallery {
    @extend .group-subpage;
    width: 100%;
    max-width: 132rem;
    height: fit-content;
    &-top {
        @extend .group-subpage-top;
        justify-content: flex-end;

        .Button:not(:last-of-type) {
            margin-right: 1rem;
        }
    }

    &-photos-icon {
        margin-right: 2rem;
    }

    &-switch {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 3rem;
    }

    &-switch-title {
        color: $white;
        font-family: $Jetbrains;
        margin-bottom: 0.5rem;
    }

    &-delete-modal {
        .ant-modal-footer {
            display: flex;
            justify-content: center;
        }
    }

    &-no-photos {
        color: #fff;
        border-radius: 0.3rem;
        background: #2b2b2b;
        padding: 3rem;
    }

    &-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(23.2rem, max-content));
        grid-gap: 2rem;
        justify-content: center;
        padding: initial;
    }

    &-image-container {
        list-style-type: none;
        width: 23.2rem;

        &.selected {
            box-shadow: 0 0 1pt 2pt $white;
            border: 1px solid $white;
        }
    }

    &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

}

.not-selected,
.selected {
    display: inline-block;
}

.react-photo-gallery--gallery>div>div {
    display: inline-block;
}

.uploadBox {
    margin-bottom: 1rem !important;
}

.uploadModal {
    .anticon.anticon-paper-clip {
        display: none !important;
    }

    .ant-upload-list-item-name {
        color: $white40;
        padding: 0 !important;
        font-size: 12px;

    }

    .ant-upload-list-item {
        margin-top: 0;

        &:first-child {}
    }

    .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-color: transparent !important;
    }

    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: 1px solid rgb(78, 78, 78);
    }

}