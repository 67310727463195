.AddGroupModal {
    &-name-input {
        margin-bottom: 2rem;

        &-title {
            margin-bottom: $sp_small;
        }
    }

    .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-color: unset !important;

        .anticon-delete svg {
            color: $primary;
        }
    }

    .ant-upload-list-item {
        * {
            color: $white30;
        }
    }

    .ant-upload-list-item-card-actions-btn:hover {}

    .Title-image-title {
        margin-bottom: $sp_small;
    }
}