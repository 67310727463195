.Dropdown {
    display: flex;
    width: fit-content;
    align-items: center;
    // margin-right: -1rem;

    .ant-space-compact-block {
        width: fit-content;
    }

    .ant-btn ant-btn-default.ant-btn-icon-only.ant-btn-compact-item.ant-btn-compact-last-item.ant-dropdown-trigger.ant-dropdown-open {
        width: fit-content;
    }

    .ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-item-rtl) {
        width: fit-content;
    }

    &-icon {
        cursor: pointer;
    }

    &-option {
        padding: .5rem 1.2rem;
    }
    &-pagination {
        display: flex;
        align-items: center;
    }
    &-showing-text {
        color: $white;
        margin-right: 1.5rem;
    }
    &-pagination-description {
        color: $foggy-grey;
        font-size: 1.2rem !important; 
        margin-left: 1.5rem;
        margin-bottom: -0.2rem;
    }
    &-pagination-page-border {
        border: 1px solid $white;
        height: 3rem;
        padding: 0 1rem 0 1rem;
        border-radius: .8rem;
    }
    &-pagination-page {
        margin-top: 1.4rem;
    }
    .ant-btn {
        background-color: none !important;
    }
    .ant-btn.ant-btn-default.ant-btn-compact-item {
        // div handling textarea left of ellipses
        &.ant-btn-compact-first-item {
            padding: 0 !important;
        }
        // div handling ellipses
        &.ant-btn-compact-last-item {
            background: none !important;
            border: none !important;
        }
    }
}

.ant-dropdown-menu {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px !important;
    background-color: lighten($grey, 5%) !important;

    // For some reason antd puts the padding on the li element despite the onclick going to the span/text itself
    // This causes clicks on the padding not to register so I override it
    &-item {
        padding: 0 !important; 
    }
}
