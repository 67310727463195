.GroupCardSquare {
    $imageHeight: 25rem;
    $textContentHeight: 8.8rem;

    &-top {
        display: flex;
        padding: 6rem 0 6rem 0;

        &-title {
            font-family: $Jetbrains;
            font-weight: 200;
            line-height: normal;
            letter-spacing: .1rem;
        }

        &-icons {
            margin-left: auto;

            &_grid-layout {
                margin-right: 1rem;
                cursor: pointer;
            }

            &_column-layout {
                cursor: pointer;
            }
        }
    }

    &-group-cards {
        flex-flow: row wrap;
        display: flex;
        margin-left: -25px;
    }

    &-group-card {
        transition: width 1s, margin-left 1s;
        flex-direction: column;
        display: flex;
        margin-left: 25px;
        margin-bottom: 20px;
        position: relative;
        height: calc($textContentHeight + $imageHeight);
        width: 33.8rem;
        background: $grey;
        position: relative;
        
        // Handle black tint effect on card image
        &::after {
            z-index: 4;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: $textContentHeight;
            background-color: rgba(0, 0, 0, 0.5); // 50% black tint
            pointer-events: none; // Allow clicks to pass through
            transition: background-color 0.3s ease-in-out;
        }
    
        &:hover::after {
            background-color: rgba(0, 0, 0, 0.2); // Remove tint on hover
        }

        // Handle red tint gradient on card text
        &:hover {
        background: linear-gradient(to right,
            rgba(lighten($primary, 10%), 0.5) 20%,
            /* Lighter primary color at 30% */
            rgba(lighten($primary, 15%), 0.3) 40%,
            /* Semi-transparent version at 50% */
            rgba(lighten($primary, 10%), 0.1) 60%,
            transparent 90%
            /* Fully transparent starts at 70% */
        );
        .GroupCardSquare-secret-value {
            font-weight: 500;
            color: $white;
        }
        .GroupCardSquare-copy-svg {
            @extend .turn-svg-white;
        }
        }
    }
    &-top {
        display: flex;
    }

    &-group-card-image {
        flex-direction: column;
        display: flex;
        cursor: pointer;
        height: $imageHeight; // Ensure this variable is defined
        width: 100%;
        padding-left: 1.9rem;
        padding-bottom: 1.2rem;
        background-size: cover;
        background-position: center;
    }

    // Image Layout
    &-text-content {
        z-index: 2;
        height: $textContentHeight;
        align-content: center;
        padding: 0 2rem 0 2rem;
    }

    &-participants {
        color: $white;
    }

    &-title-value {
        margin-bottom: 0.8rem;
        cursor: pointer;
        font-family: $Bebas !important;
        font-size: 2.6rem;
        line-height: 2.2rem;
        @extend .truncate;
    }

    &-card-description {
        margin-top: auto;
        margin-bottom: 1.3rem;
        left: 1.9rem;
        font-weight: 200;
        line-height: 18px;
        z-index: 5;
        @extend .multi-line-truncate;
        -webkit-line-clamp: 5;
    }

    &-card-wrapper {
        cursor: pointer;
        display: flex;
        position: relative;
        width: fit-content;

        &.key {}

        &.count {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 1.5rem 2.0rem 0 0;
            cursor: pointer;
            font-family: $Jetbrains;
            font-weight: 200;
            z-index:5;
            margin-left: auto;
        }
        &.title {
        }
    }

    &-card-icon {
        color: #999999;
        width: 3rem;
        margin-right: 1rem;

        &.count {
            margin-right: 0;
        }

        &.copy {
            margin-left: 1rem;
        }
    }

    &-copy-svg {
        height: 2rem;
        max-width: 100%;
        max-height: auto;
        margin-bottom: 0.3rem;
    }

    &-count-svg {
        // height: 2rem;
        max-width: 2rem;
        max-height: auto;
    }

    &-secret-value {
        font-weight: 500;
        color: $primary;
    }

    &-member-count-value {
        // color: #999999;
        @extend .no-space;
    }

    &-card-title {
        color: #999999;
    }
}