.GroupDetail {
    position: relative;
    width: 100%;
    height: fit-content;
    @extend .group-subpage;
    max-width: 100rem;

    &-edit-ellipses {
        position: absolute;
        right: 2rem;
        top: 1rem;
        z-index: 101;
    }
    &-form-secret-word {
        width: fit-content;
        &-text {
            white-space: nowrap;
        }
    }
    &-form-display-text {
        font-weight: 200;
    }
    &-group-title {
        font-family: $Bebas;
        font-size: 2.6rem;
        line-height: 2.4rem;
    }
    &-top {
        display: flex;
    }
    &-left {
        width: 33.8rem;
        margin-right: 2.5rem;
        display: flex;
        flex-direction: column;
    }
    &-right {
        width: 100%;
    }

    &-group-leadr {
        width: 50%;
    }

    &-option-wrapper {
        display: flex;
        align-items: center;
    }
    &-option-avatar {
        border-radius: .5rem;
        width: 130px;
        height: 71px;
        object-fit: cover;
        
    }
    &-option-name {
        font-family: $Jetbrains;
        font-variation-settings: 'wght' 600;
        color: $primary;
    }
    &-option-status {
        font-size: 1.4rem;
    }
    &-option-text-wrapper {
        margin: 0 $sp_medium 0 $sp_medium;
        width:13rem;
    }
    &-option-btn {
        margin-left: auto;
    }
    
    &-select-leader {
        width: 100%;
        padding-top: 5rem;
        display: flex;
        align-items: center;
        height: 4rem;

        &-title {
            font-family: $Jetbrains;
            margin-bottom: 0.3rem;
            color: $primary;
        }

            .ant-select-item-option-content {
        white-space: unset !important;
    }

        .ant-select-selection-search-input {
            height: 4rem !important;
        }


        .ant-select-selector {
            height: 4rem !important;
            border-radius: 0.5rem !important;
            font-size: 1.6rem;
            background: #272727 !important;
            border: none !important;
            border-radius: 0.5rem;
            color: $white;
            display: flex;
            align-items: center;
        }

        .ant-select-arrow {
            color: #999999;
        }
    }

    .JInput {
        margin-bottom: $sp_medium;
    }
    &-image {
        width: 33.8rem;
        height: 100%;
        height: 25rem;
        // padding-left: 21px;
        background-size: cover;
        background-position: center;
        border-radius: 0.5rem;
    }
    .uploadBox {
        opacity: 0;
        &:hover {
            opacity: 0.9;
        }
    }
    &-secret-word-wrapper {
        display: flex;
        .info {
            color: $primary;
            margin-left: 0;
        }
    }
    
}

.ant-select-item-option-content {
    white-space: unset !important;
}

