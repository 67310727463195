.Datepicker {
    width: 100%;
    &-input {
        width: 100%;
        border-radius: 0.5rem !important;
        height: $input_height;
    }
    &-calendar {

    }
    &-caption {
        @extend .joinr-form-subtitles;
    }
    &-suffix-icon {
        @extend .turn-svg-white;
    }
}

.ant-picker {
    &-month-btn,
    &-year-btn,
    &-header-super-prev-btn,
    &-header-prev-btn,
    &-header-super-next-btn,
    &-header-next-btn {
        color: $white !important;
    }
    &-large .ant-picker-input>input {
        font-size: 1.4rem;
    }
}

