.Testing {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 120rem;
    @extend .group-subpage;

    &-user-container {
        display: flex;
        align-items: center;
    }

    &-user-image {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        object-fit: cover;
    }

    &-user-name {
        margin-left: 1rem;
    }

    &-add-button {
        margin-right: auto;
    }

    &-header {
        h2 {
            margin: 0;
            font-size: 1.25rem;
            font-weight: 500;
        }
    }

    &-header-controls {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &-header-controls-right {
        display: flex;
        align-items: center;
    }

    &-leader-container {
        margin-top: 6rem;
        display: flex;
        justify-content: space-between;
    }

    &-sort-dropdown {
        position: relative;
    }

    &-sort-select {
        padding: 0.8rem 1.2rem;
        border: 0.1rem solid #363636;
        border-radius: 0.4rem;
        background-color: $grey;
        color: white;
        font-size: 1.4rem;
        cursor: pointer;
        min-width: 15rem;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat;
        background-position: right 1.2rem top 50%;
        background-size: 0.8rem auto;
        padding-right: 3rem;

        &:focus {
            outline: none;
            border-color: #464646;
        }

        option {
            background-color: $grey;
            color: white;
        }
    }

    &-search-box {
        position: relative;
        width: 20rem;
    }

    &-search-input {
        width: 100%;
        padding: 0.8rem 3.6rem 0.8rem 1.2rem;
        border: 0.1rem solid #363636;
        border-radius: 0.4rem;
        background-color: $grey;
        color: white;
        font-size: 1.4rem;
        transition: border-color 0.2s;

        &:focus {
            outline: none;
            border-color: #464646;
        }

        &::placeholder {
            color: #888;
        }
    }

    &-search-icon {
        position: absolute;
        right: 1.2rem;
        top: 50%;
        transform: translateY(-50%);
        color: #888;
        pointer-events: none;
    }

    &-table-wrapper {
        width: 100%;
        overflow-x: auto;
    }

    &-table {
        width: 100%;
        border-collapse: collapse;
        color: white;

        th,
        td {
            padding: 1rem;
            text-align: left;
        }

        thead {
            tr {
                background-color: $dark-grey;
            }

            th {
                font-weight: 500;
            }
        }

        tbody {
            border-top: 0.2rem solid #811F2D;
        }
    }

    &-row-even {
        background-color: $dark-grey;
    }

    &-row-odd {
        background-color: $grey;
    }

    &-pagination {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.6rem;
        color: white;
    }

    &-pagination-right {
        display: flex;
    }

    &-pagination-numbers {
        display: flex;
        gap: 0.8rem;
        align-items: center;
    }

    &-pagination-ellipsis {
        color: white;
        padding: 0.8rem;
        user-select: none;
        padding: 0;
    }

    &-pagination-button,
    &-pagination-number {
        padding: 0.4rem 1rem;
        border-radius: .8rem;
        background-color: $grey;
        border: none;
        color: white;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover:not(:disabled) {
            background-color: $primary;;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &-pagination-number {
        &.Testing-active {
            background-color: #ee002e;
        }
    }

    &-items-per-page {
        .items-per-page-select {
            padding: 0.6rem 1.2rem;
            border: 0.1rem solid #ddd;
            border-radius: 0.4rem;
            background-color: white;
            cursor: pointer;

            &:focus {
                outline: none;
                border-color: #007bff;
            }
        }
    }
}