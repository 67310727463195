.ContactCard {
    display: flex;
    width: 40rem;
    background: $black;
    padding-left: 1.5rem;
    align-items: center;
    height: 8rem;
    border-radius: 0.3rem;
    &:hover {
        cursor: pointer;
    }
    &-left {
        img {
            height: auto;
            width: 6rem;
            border-radius: 5rem;
        }
    }
    &-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1rem;
    }
    &-name {
        transition: all 0.3s;
        color: $white;
        font-size: 1.8rem;
        font-variation-settings: 'wght' 600;
    }
    &-description {
        color: $white40;
    }
}
