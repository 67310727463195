.Waypoint {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    &-image-container {
        position: relative;
        width: 11.3rem;
        cursor: default;
        position: relative;

        .edit-mode & {
            cursor: pointer;
        }
    }

    &-click-image-icon {
        display: none;

        .edit-mode & {
            display: block;
        }

        position: absolute;
        z-index: 1;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    &-fade-screen {
        display: none;

        .edit-mode & {
            display: block;
        }

        width: 100%;
        height: 100%;
        background-color: black;
        position: absolute;
        opacity: 0.60;
        border-radius: .5rem;
    }

    &-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: .5rem;
        opacity: 0.6;

        .edit-mode & {
            opacity: .35;
        }
    }

    &-fade-style {
        height: 100%;
        position: absolute;
        width: 100%;
        background: linear-gradient(90deg, rgba(48, 49, 49, 0) 78.49%, #303131 101.14%) !important;
        z-index: 999;

        .edit-mode & {
            display: none;
        }
    }

    &-text-area {
        margin-top: 1rem !important;

        * {
            height: fit-content;
        }

        .Input-img-right {
            visibility: hidden;
        }

        textarea.ant-input {
            min-height: 5.8rem !important;
            resize: none;
            background-color: $grey !important;
            padding: 0;

            .edit-mode & {
                padding: .4rem 1.1rem;
                resize: vertical;
                background-color: #272727 !important;
            }
        }
    }

    .ant-form-item-control-input-content {
        width: 440px;
    }

    &-start-end-icon {
        width: 2rem;
        height: 2.5rem;
        margin-top: 3.2rem;
        margin-left: 1rem;
        margin-right: 1rem;
        cursor: grab;

        &-container {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 7rem;
                left: 50%;
                transform: translate(-50%);
                width: 1.4rem;
                height: calc(calc(100% - 8rem) + 3.7rem);
                background-image: url('../../../src/assets/img/roadbook/grey-dot.svg');
                background-size: 100% .7rem;
            }
        }

        &:active {
            cursor: grabbing;
        }
    }

    &-add-description {
        color: #888888;
        font-size: 1.2rem;
    }

    &-flex-col {
        display: flex;
        flex-direction: column;
    }

    &-flex-row {
        display: flex;
        flex-direction: row;
        position: relative;
    }

    &-add-description {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
    }

    &-description-options {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.3rem;
        margin-right: 2.8rem;
    }

    &-description-option {
        color: #888888;
        font-size: 1.2rem;
        margin-left: .5rem;
        cursor: pointer;
    }

    .hidden {
        display: none;
    }

    &-delete-icon {
        cursor: pointer;
        margin-top: 3.4rem;
        margin-left: 1.5rem;
        visibility: hidden;

        .edit-mode & {
            display: block;
            visibility: visible;
        }

        &.RouteStart {
            visibility: hidden;
        }
    }

    &-delete-image-icon {
        position: absolute;
        top: 0.8rem;
        left: 0.8rem;
        cursor: pointer;
        visibility: hidden;
        z-index: 9999;

        .edit-mode & {
            display: block;
            // visibility: visible;
        }
    }

    &-image-container:hover {


        .edit-mode & {
            .Waypoint-image {
                opacity: 0.20 !important;
            }


            .Waypoint-delete-image-icon {
                visibility: visible;
            }
        }


    }

    &-spinner {
        position: absolute !important;
        z-index: 1;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    .ant-spin-dot-item {
        background-color: $white !important;

    }
}