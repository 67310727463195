.RoadbookDay {
    width: 100%;
    margin-bottom: 4rem;
    transition: all 300ms;
    background-color: $grey;

    .Waypoint {
        &:last-child {
            .Waypoint-start-end-icon-container {
                &::before {
                    display: none;
                }
            }
        }
    }

    .Input-error {
        position: absolute;
        font-size: 1.3rem;
        left: 47rem;
    }

    &-gmap-input {
        input {
            color: #888888 !important;
            pointer-events: none;

            .edit-mode & {
                pointer-events: all;
                color: $white !important;
            }
        }
    }

    &-top {
        display: flex;
        align-items: center;
        margin-bottom: 2.6rem;
    }

    &-day-text {
        color: $white;
    }

    &-left-container {}

    &-day-container {
        display: flex;
        align-items: center;
    }

    &-date-container {
        display: flex;
        align-items: center;
        margin-left: 2.5rem;
    }

    &-datePicker-date {
        width: 10rem;
        color: #888888;
        font-size: 1.2rem;
        cursor: pointer;
        background: none;
        border: none;
    }

    &-date-text {}

    &-chevron-icon {
        cursor: pointer;
        padding: 1rem;
        margin: -1rem 0rem -1rem -1rem;
        transform: rotate(-90deg);
        transition: all 150ms;

        &.inactive {
            transform: rotate(-180deg);
            transition: all 150ms;
        }
    }

    &-gmaps-icon {
        cursor: pointer;
        margin-left: 1.1rem;
        width: 3.2rem;
        height: 3.2rem;
    }

    &-pencil-icon {
        cursor: pointer;
        margin-left: .5rem;
    }

    &-gmap-input {
        margin-left: 2.7rem;
    }

    &-date-picker {
        padding: 0 !important;

        input {
            // color: #888888 !important;
            font-size: 1.2rem !important;
            cursor: pointer !important;
            width: 7rem !important;
        }
    }

    &-delete-icon {
        margin-left: 2.3rem;
        cursor: pointer;
        display: none;

        .edit-mode & {
            display: block;
        }
    }
}