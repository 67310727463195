.Button {
    min-width: 11rem;
    min-width: fit-content;
    &-cancel {
        background-color: $white !important;
        border: $white !important;
        color: $primary !important;
    }
    width: 157px;
    height: 47px !important;
    & > span::first-letter {
        text-transform: uppercase;
    }
}