.Header {
    min-height: 7.4rem;
    width: 100%;
    background: $pure_black;
    display: flex;
    align-items: center;
    position: relative;

    &-right {
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    &-search-box {
    }
    &-bell-icon {
        padding: 1rem;
        cursor: pointer;
    }

    &-user-avatar {
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        object-fit: cover;
        cursor: pointer;
    }
    // Bottom line
    &::before {
        content: '';
        height: 0.1rem;
        width: 100%;
        radius: 0.7rem;
        bottom: 0;
        background: $foggy-grey;
        position: absolute;
        left: 50%; // Position the left edge at the center
        transform: translateX(-50%);
    }
    &-active-tab-title {
        font-family: $Bebas;
        font-size: 24px;
        font-weight: 400;
        line-height: 28.8px;
        text-align: left;
        color: $primary;
    }
    &-account {
        color: $white;
        margin-left: auto;
        margin-right: 4rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &-display-name {
        margin-right: 1rem;
    }
}
