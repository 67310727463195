// NEW
@font-face {
    font-family: 'Bebas';
    src: url('../assets/fonts/BebasNeue-Regular.ttf') format('truetype');
}

$Bebas: 'Bebas', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    'Helvetica Neue', sans-serif;

@font-face {
    font-family: 'Jetbrains';
    src: url('../assets/fonts/JetBrainsMono-VariableFont_wght.ttf') format('truetype');
}

$Jetbrains: 'Jetbrains', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;