.ModalEditProfile {
    &-group-name {
        // margin-bottom: 0 !important;
        .Input-input {
            font-family: $Bebas !important;
            font-size: 2.6rem !important;
        }
    }
        &-option-name {
            @extend .joinr-form-subtitles;
            color: $white;
            transition: all 150ms;
            &:hover {
                color: $primary;
                transition: all 150ms;
            }
        }
        &-flex {
            flex-direction: row;
        }

}