// BASE
@import 'fonts';
@import 'reset';
@import 'theme';
@import 'devextreme/dist/css/dx.common.css';
@import 'devextreme/dist/css/dx.light.css';

// REUSABLE
@import 'reusable';

// PAGES
@import 'pages/account';
@import 'pages/contacts';
@import 'pages/groups';
@import 'pages/login';
@import 'pages/overview';
@import 'pages/groupdetail';
@import 'pages/members';
@import 'pages/gallery';
@import 'pages/roadbook';
@import 'pages/admin';
@import 'pages/notfound';

// GENERIC COMPONENTS
@import 'components/generic/button';
@import 'components/generic/select';
@import 'components/generic/Input';
@import 'components/generic/title';
@import 'components/generic/modal';
@import 'components/generic/uploader';
@import 'components/generic/dropdown';
@import 'components/generic/datepicker';
@import 'components/generic/setting';
@import 'components/generic/search';
@import 'components/generic/table';

// COMPONENTS
@import 'components/locationdropdown';
@import 'components/contactcard';
@import 'components/groupcard';
@import 'components/header';
@import 'components/sidebar';
@import 'components/waypoint';
@import 'components/roadbookday';
@import 'components/groupdetailsnavbar';
@import 'components/groupcardsquare';
@import 'components/groupcardhorizontal';

// MODLAS
@import 'components/modals/modaladdgroup';
@import 'components//modals/modalcreategroup';
@import 'components//modals/modaleditgroup';
@import 'components//modals/modaleditprofile';

// MISC
@import '~toastr/toastr.scss';
@import 'react-image-lightbox/style.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import 'zindex.scss';
@import 'datagrid.scss';

// TESTING
@import 'testing.scss';

.App {
    &-sidebar-header-wrapper {
        display: flex;
        height: 100vh;
    }

    &-header-main-wrapper {
        padding-left: 6.6rem;
        padding-right: 6.6rem;
        display: flex;
        flex-direction: column;
        width:100%;
    }
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

// GENERAL STYLES
.ant-tooltip {
    max-width: fit-content !important;
}

.main-content {
    background: $pure_black;
    // TODO: Fix this... its 100vw - sidebar - padding
    width: calc(100vw - 26.4rem - 13.2rem);
    display: flex;
    height: 100vh;
    overflow-y: auto;
    margin-bottom: 4.8rem;
}

.ant-row.ant-form-item {
    margin-bottom: 0 !important;
}

* {
    font-family: $Jetbrains;
}

body * {
    font-size: 1.4rem;
}

img {
    @extend .no-select;
    @extend .no-drag;
}

.error {
    color: red;
}

.ant-btn {
    line-height: 0 !important;
}

#toast-container>div {
    top: 9rem;
    right: 3.6rem;
}

.toast-success {
    box-shadow: none !important;
    background-color: $primary !important;

    .toast-message {
        color: $white;
    }
}

.toast.toast-error {
    box-shadow: none !important;
    background-color: $primary !important;

    .toast-message {
        color: $white;
    }
}

// ANTD
.ant-input[disabled] {
    background: #2e2e2e !important;
    color: $white !important;
}

.ant-btn.ant-btn-ghost {
    background: rgb(59, 59, 59) !important;
    color: #656565 !important;
    border-color: rgb(75, 75, 75) !important;
    cursor: default !important;
    pointer-events: none !important;
}