.Search {
    &-container {
        margin-right: -1rem;
        display: inline-flex;
        align-items: center;
        width: 3.6rem; // Increased to account for icon padding (1.6rem + 2rem padding)
        position: relative;
        transition: width 0.3s ease;
    }

    &-input-wrapper {
        width: 100%;
        position: absolute;
        right: 4.6rem; // Adjusted for new total icon width (1.6rem + 2rem padding + 1rem gap)
        opacity: 0;
        transform: translateX(1rem);
        transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &-icon {
        width: 1.6rem;
        height: 1.6rem;
        cursor: pointer;
        position: absolute;
        right: 1rem; // Add padding on right
        padding: 1rem; // Add padding all around
        box-sizing: content-box; // Ensure padding adds to width/height
    }

    &-input {
        width: 100%;
        padding: 0.8rem 1.2rem;
        border: 0.1rem solid #363636;
        border-radius: 0.4rem;
        background-color: $grey;
        color: white;
        font-size: 1.4rem;
        visibility: hidden;

        &:focus {
            outline: none;
            border-color: #464646;
        }

        &::placeholder {
            color: #888;
        }
    }

    &-expanded {
        width: 32rem; // Adjusted for new icon width (30rem + 2rem padding)

        .Search-input-wrapper {
            opacity: 1;
            transform: translateX(0);
        }

        .Search-input {
            visibility: visible;
        }

        .Search-icon {
            cursor: default;
        }
    }
}