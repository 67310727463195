.Setting {
    width: 100%;
    cursor: pointer;

    &-switch-wrapper {
        display: flex;
        align-items: center;

        &-inner {
            min-width: 20rem;
        }
    }

    &-title {
        @extend .joinr-form-subtitles;
        font-size: 1.6rem;
        &-white {
            color: $white;
        }
    }

    &-icon {
        display: inline-block;
        align-self: flex-start;
        margin-right: 2.8rem;
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
    }
}