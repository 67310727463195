.NotFound {
    width: 100%;
    background-color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100vh - $header_height);

    &-content {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        text-align: center;
    }

    &-title {
        color: $primary;
        font-size: 5rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    &-subtitle {
        color: $primary;
        font-size: 1.875rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    &-message {
        color: $foggy-grey;
        font-size: 1.125rem;
        margin-bottom: 2rem;
        max-width: 28rem;
        margin-left: auto;
        margin-right: auto;
    }

    &-buttons {
        display: flex;
        gap: 1rem;
        justify-content: center;
        width: 100%;
    }

    &-footer {
        margin-top: 3rem;
        color: $foggy-grey;
        width: 100%;
        text-align: center;
    }
}