.Title {
    font-family: $Bebas;
    font-variation-settings: 'wght' 600;
    color: $white;

    &-small {
        @extend .Title;
    }

    &-medium {
        @extend .Title;
        font-size: 2.6rem;
    }

    &-large {
        @extend .Title;
        font-size: 3.6rem;
    }
}
