.Table {
    $spacing: 1rem;
    $border-radius: 0.4rem;

    width: 100%;
    max-width: 120rem;
    @extend .group-subpage;
    height: fit-content;

    // Common styles mixin
    @mixin flex-center {
        display: flex;
        align-items: center;
    }
    &-no-results {
        text-align: center;
        padding: 20px;
        color: #666;
        font-size: 14px;
    }
    // Header styles
    &-header-controls {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &-right {
            @include flex-center;
        }
    }

    // User styles
    &-user {
        &-container {
            @include flex-center;
        }

        &-image {
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 50%;
            object-fit: cover;
        }

        &-name {
            margin-left: $spacing;
        }
    }

    // Table styles
    &-table {
        width: 100%;
        border-collapse: collapse;
        color: white;

        td {
            padding: $spacing;
            text-align: left;
        }

        tbody {
            border-top: 0.2rem solid #811F2D;
        }
    }

    &-row {
        &-even {
            background-color: $dark-grey;
        }

        &-odd {
            background-color: $grey;
        }
        &-inactive {
            opacity: 0.5;
            pointer-events: none;

            // Override pointer-events for specific elements that should still be visible
            .Table-user-container {
                pointer-events: auto;
            }
        }
    }

    // When kicked or left 
    &-status-tag {
        display: inline-block;
        margin-left: 0.8rem;
        padding: 0.2rem 0.6rem;
        border-radius: 0.3rem;
        background-color: $dark-grey;
        font-size: 1.2rem;
        color: #ffffff80;
    }

    // Pagination
    &-pagination {
        @include flex-center;
        justify-content: space-between;
        padding: 1.6rem;
        color: white;

        &-right {
            display: flex;
            gap: 0.8rem;
        }

        &-button {
            padding: 0.4rem $spacing;
            border-radius: $border-radius;
            background-color: $grey;
            border: none;
            color: white;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover:not(:disabled) {
                background-color: $primary;
            }

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
            &.Table-active {
                background-color: $primary;
            }
        }
        &-numbers {
            display: flex;
            gap: 0.8rem;
            align-items: center;
        }

        &-ellipsis {
            color: white;
            user-select: none;
        }
    }

    // Utility classes
    &-search-box {
        width: 20rem;
    }

    &-add-button {
        margin-right: auto;
    }

    &-table-wrapper {
        width: 100%;
        overflow-x: auto;
    }

    // Leader section
    &-leader-container {
        margin: 6rem 0 2rem;
        display: flex;
        justify-content: space-between;
    }

    &-leader {
        @include flex-center;
        gap: $spacing;
    }
}