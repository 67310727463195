.LocationDropdown {
    &-title-wrapper {
        display: flex;
        align-items: center;
    }

    &-title {
        color: $primary !important;
        margin-bottom: 1rem;

        &.bold {
            font-family: $Jetbrains;
            font-variation-settings: 'wght' 600;
        }
    }

    &-tooltip {
        width: 16px;
        height: auto;
        margin-left: .5em;
        margin-bottom: .3rem;
    }

    .ant-form-item {
        margin-bottom: 0 !important;
    }

    .location-input {

        @extend .ant-input-generic;
        width: 100%;
        height: 4.8rem;

        .edit-mode & {
            pointer-events: all;
            color: $white;
        }

    }

    &-error {
        color: $primary;
        margin-top: 0.5rem;
    }

    .ant-form-item-control-input-content {
        display: flex !important;

        .InputAutocomplete-img-right {
            margin-left: 1.2rem;
            cursor: pointer;
        }
    }
}