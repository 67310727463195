.bg-circle {
    position: absolute;
    height: 3.1rem;
    width: 3.1rem;
    background-color: $pure_black;
    border-radius: 100%;
    bottom: 0.5rem;
    right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Account {
    width: 100rem;
    padding-top: 5rem;
    &-uploadBox {
        height: 23rem !important;
    }
    &-icon-camera-bg-splash {
        @extend .bg-circle;
        top: 1rem;
    }
&-avatar-container {
    position: relative;
}

&-profile-spinner {
    position: absolute;
    @extend .absolute-center;
    z-index: 3; // Keep spinner on top
    cursor: default;
}

&-userImage {
    border: 0.8rem solid $white;
    border-radius: 100%;
    margin-top: -13rem;
    margin-left: 13rem;
    position: relative;
    z-index: 3;

    .Account-icon-camera-bg {
        @extend .bg-circle;
        z-index: 4; // Above overlay but below spinner
    }

    .Account-icon-camera {
        width: 1.8rem;
        height: 1.8rem;
    }

    .Account-avatar {
        position: relative;
        border-radius: 100%;
        object-fit: cover;
        height: 16rem;
        width: 16rem;
        cursor: pointer;
    }

    .Account-avatar-overlay {
        // New class for the overlay
        position: absolute;
        width: 16rem; // Match avatar width
        height: 16rem; // Match avatar height
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 100%;
        pointer-events: none;
        z-index: 1; // Above image, below camera icon
    }
}
&-top {
    margin-left: 25rem;
    &-flex {
        display: flex;
        align-items: center;
    }
}
&-icon-calendar {
    margin-right: 2rem;
}
&-semicolon {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
&-user {
    color: $white;
    &-name {
        font-weight:300;
        font-size: 1.8rem;
    }
    &-location {
        color: $foggy-grey;
        font-family: $Jetbrains;
        font-weight:300;
        font-size: 1.4rem;
        margin-top: 1rem;
    }
    &-joined {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
}
&-settings-text {
    font-family: $Bebas;
    font-size: 1.8rem;
}
&-socials {
    &-option {
        display: flex;
        gap: 2rem;
        align-items: center;
    }
    &-icon {
        width: 2rem;
        height: 2rem;
    }
    &-text {
        color: $white;
    }
}
    &-splash-image {
        position: relative;
        width: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 0.5rem;
        &-overlay {
                    // New class for the overlay
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        pointer-events: none;
                        z-index: 1; // Above image, below camera icon
        }
    }
    &-column-content {
        display: flex;
        gap: 6rem;
    }

    &-left {
        width: 50%;
    }
    &-right {
        width: 50%;
    }
  
        .ant-upload.ant-upload-disabled {
        cursor: default !important;
    }

}

.ant-upload.ant-upload-drag {
    background: unset !important;
}
.ant-upload.ant-upload-select-picture-card {
    border: none !important;
    background: unset !important;
}
.ant-upload-picture-card-wrapper {
    height: 0;
}