.Groups {
    width: 100%;
    &-spinner {
        margin-top: 10rem !important;
    }
    &-top {
        display:flex;
        padding: 6rem 0 6rem 0;
        &-title {
            font-family: $Jetbrains;
            font-weight: 200;
            line-height: normal;
            letter-spacing: .1rem;
        }
        &-icons {
            margin-left:auto;
            &_grid-layout {
                margin-right: 1rem;
                cursor: pointer;
            }
            &_column-layout {
                cursor:pointer;
            }
        }
    }
}