$primary: #ee002e;
$grey: #262626; // new design color
$foggy-grey: #808080;
$dark-grey: #212121;
$black: #141414;
$pure_black: $black;
$white: #fff;

$white30: rgba(255, 255, 255, 0.3);
$white40: rgba(255, 255, 255, 0.4);

$input-grey: #212121;

// SPACING
$sp_small: 0.5rem;
$sp_medium: 2rem;
$input_10: 1rem;

// DIMENSIONS
$header_height: 7.4rem;
$sidebar_width: 26.4rem;

// Z-INDEX-VALUES
$z_index_sidebar: 100;

// PADDING
$main_content_padding: 1rem;

// INPUT SIZING -- used across generic components
$input_height: 4.8rem;


body,
#root {
    background-color: $black;
}

.foggy-grey {
    color: $foggy-grey;
}

// shared across all group tabs
.group-subpage {
    background-color: $grey;
    padding: 4rem;
    max-width: 152rem;

    .ant-table-cell {
        cursor: pointer;
    }
}

.group-subpage-top {
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: space-between;
}

//=============================== SPECIFIC TO FORMS =============================== //
.column-layout {
    display: flex;
    gap: 2rem;
}


// form subtitle, input or text display goes underneath
.joinr-form-subtitles {
    color: $primary;
    font-family: $Jetbrains;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: $input_10;

    &.bold {
        font-family: $Jetbrains;
        font-variation-settings: 'wght' 600;
    }
}

.ant-input-generic {
    font-size: 1.4rem;
    background: $input-grey;
    border: none;
    border-radius: 0.5rem;
    color: $white;
    padding: 1.3rem 2rem;
    height: fit-content;
    width: 100%;
    height: 4.8rem;
}

.ant-input.Input-text-area {
    height: unset;
    min-height: 10rem;
}

.ant-switch-handle::before {
    background-color: $white !important;
}

.divider {
    height: .1rem;
    width: 100%;
    background-color: $dark-grey;   
}