.Login {
    height: 100%;
    background: $grey;
    padding: 5rem;
    &-main-content {
    }
    &-background-art {
        position: absolute;
        height: 100vh;
        width: 100%;
        z-index: 0;
        pointer-events: none;
    }
    &-header {
        padding-bottom: 5rem;
    }

    &-logo {
        height: 7rem;
    }
    &-main-content {
        display: flex;
        justify-content: center;
        background: $grey;
    }
    &-left {
        display: flex;
        img {
            height: auto;
            width: 90%;
            min-width: 500px;
            z-index: 1;
            @media all and (max-width: 1023px) {
                display: none;
            }
        }
    }
    &-right {
        display: flex;
        align-items: center;
        @media all and (min-width: 375px) {
            margin-bottom: 10rem;
        }
    }

    &-forgot-password-text {
        color: $foggy-grey !important;
        transition: all 0.15s linear;
        cursor: pointer;
        &:hover {
            color: $primary !important;
        }
    }
}

/* Safari autofill for all input types including password */
input:-webkit-autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable,
input[type="password"]:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $input-grey inset !important;
    -webkit-text-fill-color: $white !important;
    transition: background-color 5000s ease-in-out 0s;
}