.Input {
    &-title-wrapper {
        display: flex;
        align-items: center;
    }

    .ant-input.Input-text-area.ant-input-disabled {
        cursor: default !important;
    }

    &-tooltip {
        width: 16px;
        height: auto;
        margin-left: .5em;
        margin-bottom: $input_10;
    }



    .ant-input.Input-input {
        height: $input_height;

        &::placeholder {
            color: #888888;
            font-size: 1.4rem;
        }
    }

    .ant-form-item {
        margin-bottom: 0 !important;
    }

    .ant-input {
        @extend .ant-input-generic;
    }

    &-text-area {
        padding: 1rem;
    }

    .ant-input[disabled] {
        color: $foggy-grey !important;
    }

    &-error {
        color: $primary;
        margin-top: 0.5rem;
    }

    .ant-form-item-control-input-content {
        display: flex !important;

        .Input-img-right {
            margin-left: 1.2rem;
            cursor: pointer;
        }
    }


}